/*
  Info: Checking on integration step2 (usually list selection) fields modification.
  Must have:
    - declare a step2Fields variable in data (step2.vue) which have to contains all savable fields (example: step2Fields: ['listId', 'optinType'])
    - trigger storeInitialStep2State method in the step2.vue mounted (it's needed because mixin's mounted will triggering before the component mounted)
    - on update (example: listId update) -> trigger emitIsModified method
*/
import { isEqual, get } from 'lodash-es';

export default {
  data() {
    return {
      originalFieldStates: null,
    };
  },
  methods: {
    storeInitialStep2State() {
      this.originalFieldStates = this.getFieldState();
    },
    isModified() {
      return !isEqual(this.originalFieldStates, this.getFieldState());
    },
    getFieldState() {
      return JSON.stringify(
        this.step2Fields.reduce((previous, current) => {
          return { [current]: get(this, current), ...previous };
        }, {}),
      );
    },
    emitIsModified() {
      this.$emit('isModified', this.isModified());
    },
  },
};
